<template>
  <b-card>
    <h3 class="text-center">
      {{ $t('classes.students') }}
    </h3>
    <filters
      :add="true"
      :search="true"
      @add-button-clicked="$bvModal.show('ClassInfoAddModal')"
      @filtered-items="filter"
    />
    <b-row>
      <b-col
        cols="12"
        class="mt-2"
      >
        <b-table
          hover
          show-empty
          responsive
          :items="GET_CLASS_STUDENTS.results"
          :fields="fields"
          class="mb-0"
          :empty-text="$t('general.no_data_to_view')"
          @row-clicked="e => $router.push(`/users/${e.id}/info/`)"
        >
          <template #head()="{ label }">
            {{ $t(label) }}
          </template>
          <template #cell(phone_number)="data">
            <span class="text-nowrap">
              {{ data.value }}
            </span>
          </template>

          <template
            #cell(actions)="data"
          >
            <div class="d-flex align-items-center no-wrap">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="btn-icon mr-50 p-25"
                size="sm"
                @click="$router.push(`/users/${data.item.id}/info/`)"
              >
                <feather-icon
                  size="22"
                  icon="ChevronsRightIcon"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-icon p-25"
                size="sm"

                @click="deleteStudent(data.item.id)"
              >
                <feather-icon
                  size="22"
                  icon="XIcon"
                />
              </b-button>
            </div>
          </template>
          <!-- Optional default data cell scoped slot -->
          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- pagination -->
    <!-- slot-scope="props" -->
    <template>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <span class="text-nowrap">{{ $t('general.show_from') }}</span>
          <b-form-select
            v-model="params.page_size"
            :options="pageOptions"
            class="mx-1"
            @change="changePerPage"
          />
          <span class="text-nowrap"> {{ $t('general.show_to') }} </span>
          <!-- из {{ props.total }} -->
        </div>
        <div>
          <b-pagination
            v-model="params.page"
            :total-rows="GET_CLASS_STUDENTS.count"
            :per-page="params.page_size"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="changePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
    <AddModal
      :def-selected="defStudents"
      :one-class="oneClass"
      @refresh="refresh"
    />
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BCard,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue'
import { required } from '@validations'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import AddModal from './classInfoModals/AddStudentModal.vue'
import areYouSure from '@/mixins/areYouSure'
import toast from '@/mixins/toast'
import filters from '@/components/filters.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    AddModal,
    BCard,
    BButton,
    filters,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
  },
  mixins: [areYouSure, toast],
  data() {
    return {
      oneClass: {
        title: '',
        start_time: null,
        end_time: null,
        capacity: null,
      },
      pageOptions: [3, 5, 10],
      params: {
        id: this.$route.params.id,
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
      required,
      fields: [
        {
          label: 'users.full_name',
          key: 'full_name',
        },
        {
          label: 'E-mail',
          key: 'email',
        },
        {
          label: 'users.phone_number',
          key: 'phone',
        },
        {
          label: 'users.level',
          key: 'level',
        },
        {
          label: 'general.actions',
          key: 'actions',
        },
      ],
      defStudents: [],
      addStudent: false,
    }
  },
  computed: {
    ...mapGetters('classes', ['GET_ONE_CLASS', 'GET_CLASS_STUDENTS']),
  },
  mounted() {
    this.FETCH_CLASS(this.$route.params.id).then(() => {
      this.updateBreadcrumb()
      this.defStudents = this.GET_ONE_CLASS.students
    })
    this.FETCH_CLASS_STUDENTS(this.params)
      .then(res => {
        this.oneClass = res
      })
      .catch(() => {
        this.$router.go(-1)
        this.$_errorToast(this.$t('general.connection_error'))
      })
  },
  methods: {
    ...mapActions('classes', ['FETCH_CLASS', 'CREATE_CLASS', 'UPDATE_CLASS', 'FETCH_CLASS_STUDENTS']),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),

    // delete student in list selected
    deleteStudent(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          const index_id = this.defStudents.indexOf(id)
          this.defStudents.splice(index_id, 1)
          this.UPDATE_CLASS({ id: this.$route.params.id, students: this.defStudents })
            .then(() => {
              this.$_successToast(this.$t('general.removed'))
              this.FETCH_CLASS_STUDENTS(this.params)
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
    refresh() {
      this.FETCH_CLASS_STUDENTS(this.params)
    },
    updateBreadcrumb() {
      const [first, second] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      second.text = `${this.GET_ONE_CLASS.title}`
      this.UPDATE_BREADCRUMB([first, second])
    },
    // for pagination and filters
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      this.FETCH_CLASS_STUDENTS(this.params)
    },
    changePage(value) {
      this.params.page = value
      this.FETCH_CLASS_STUDENTS(this.params)
    },
    filter(filteredData) {
      this.params.name = filteredData.search
      this.FETCH_CLASS_STUDENTS(this.params)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
